import React from 'react'
import {useHistory} from "react-router"

import styled from "styled-components"
import { IoPodiumOutline } from "react-icons/io5";
import * as BsIcons from 'react-icons/bs'
import Text from '../styledComponents/TextStyled'

const GridCustom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer !important;
  color: white;
  align-items: center;
  text-align: center;
`
const GridCustomItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 8px;
`

export default function CardProgressive({item}) {
  const {id, name: promoName,levels} = item


  const history = useHistory();

  const goToView = () => {
    history.push('/progressive-view/' + id)
  }


  return (
    <GridCustom onClick={() => goToView()}>
      <GridCustomItem>
          <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
            <BsIcons.BsTrophy fontSize={28}/>
            <Text textStyle='small'>Progresivo</Text>
          </div>
          <Text textStyle='large'>{promoName}</Text>
        </GridCustomItem>
      <GridCustomItem>
        <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
          <IoPodiumOutline fontSize={28}/>
          <Text textStyle='small'>Pozos</Text>
        </div>
        <Text textStyle='large'> {levels.length} </Text>
      </GridCustomItem>
    </GridCustom>
  );
}
