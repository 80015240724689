export const truncateName = (firstName, lastName1, hideName) => {
  if (hideName) {
    const truncatedFirstName = firstName.substring(0, 4) + '**** ';
    const truncatedLastName = lastName1.substring(0, 4) + '**** ';
    return `${truncatedFirstName}  ${truncatedLastName}`;
  } else {
    return `${firstName} ${lastName1}`;
  }
};

export const getFirstLetters = (name, lastName) => {
  return name.charAt(0) + '. ' + lastName.charAt(0)
}

export const labelOfPagina = (from, to, count) => {
  return `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`
};

export const getCurrentTime = () => new Date().getTime();

export const getTimeRemaining = (endtime) => {
  const total = Date.parse(endtime) - Date.parse(new Date())
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / 1000 * 60 * 60) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))
  return {
    total, days, hours, minutes, seconds
  }
}

export const getDeadLineTime = () => {
  let deadline = new Date()
  deadline.setMinutes(deadline.getMinutes() + 1)
  return deadline
}

export const getSizeTitle = (item) => {
  switch (item) {
    case 1:
      return (
        {
          fontSize: '6vw',
          textShadow: '0 2px 6px #2d0000'
        }
      )
    case 2:
      return (
        {
          fontSize: '5vw',
          textShadow: '0 2px 6px #2d0000'
        }
      )
    default:
      return (
        {
          fontSize: '4vw',
          textShadow: '0 2px 6px #2d0000'
        }
      )
  }
}

export const getSizeAmount = (item) => {
  switch (item) {
    case 1:
      return (
        {
          fontSize: '9vw',
          textShadow: '0 4px 8px #2d0000',
          top: '62%'
        }
      )
    case 2:
      return (
        {
          fontSize: '8vw',
          textShadow: '0 4px 8px #2d0000',
          top: '65%'
        }
      )
    case 3:
      return (
        {
          fontSize: '5vw',
          textShadow: '0 4px 8px #2d0000',
          top: '64%'
        }
      )
    default:
      return (
        {
          fontSize: '8vw',
          textShadow: '0 4px 8px #2d0000',
          top: '65%'
        }
      )
  }
}