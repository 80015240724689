import React from 'react'
import {useHistory} from "react-router"
import styled from "styled-components"

import {DateTime} from "luxon"
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs'
import Text from '../styledComponents/TextStyled'
import {getCurrencyConfig} from "./services";

const GridCustom = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  cursor: pointer !important;
  color: white;
  align-items: center;
  text-align: center;
`
const GridCustomItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap:8px;
`

export default function CardTorneo({item}) {
  const {couponTypeId: torneoId, premioTipo, amount, couponTypeName: promoName} = item

  const fechaHora = DateTime.fromISO(item.fechaHora).toFormat('dd/MM hh:mm a')

  const history = useHistory();

  const goToView = () => {
    history.push('/torneo-view-rank/' + torneoId)
  }

  const currencyConfig = getCurrencyConfig()

  return (
    <GridCustom onClick={() => goToView()}>
      <GridCustomItem>
        <MdIcons.MdOutlineAccessTime fontSize={32}/>
        <Text textStyle='large'> {fechaHora} </Text>
      </GridCustomItem>
      <GridCustomItem>
        <BsIcons.BsTrophy fontSize={32}/>
        <Text textStyle='large'> {promoName} </Text>
      </GridCustomItem>
      {premioTipo === 'soles' ?
        <GridCustomItem>
          <MdIcons.MdLocalAtm fontSize={32}/>
          <Text textStyle='large'>{currencyConfig.symbol} {amount}</Text>
        </GridCustomItem>
        :
        <GridCustomItem>
          <BsIcons.BsGiftFill fontSize={32} />
          <Text textStyle='large'>{amount} {premioTipo}</Text>
        </GridCustomItem>
      }
    </GridCustom>
  );
}
