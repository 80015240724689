import React, {useEffect} from "react";
import {useWauth} from "../auth/WauthProvider";

const LogoutPage = () => {

  localStorage.clear();

  const {logout} = useWauth()

  useEffect(() => {
    logout({returnTo: window.location.origin})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>Loading...</div>
  )
}

export default LogoutPage

export const logoutUser = (dispatch) => {//logout
  localStorage.removeItem("auth");
  goToExternalLogout();
};

const goToExternalLogout = () => {
  const authorizeEndpoint = process.env.REACT_APP_AUTH_SERVER + '/auth/logout'
  const redirectTo = window.location.origin + '/login'
  const params = {redirect_uri: redirectTo, client_id: process.env.REACT_APP_AUTH_CLIENT_ID}
  const paramString = new URLSearchParams(params)
  window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
}
