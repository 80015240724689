import React, {createContext, useEffect, useState} from 'react';
import {datadogRum} from "@datadog/browser-rum";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {useHistory} from "react-router";
import axios from "axios";

export const SiteContext = createContext({
  salaId: null,
  setSalaId: () => {},
  info: {},
  setInfo: () => {},
  sorteosConf: null,
  setSorteosConf: () => {},
});

export const SiteProvider = (props) => {

  //TODO, sala y salaId podrian guardarse en un solo item de local storage, es necesario guardarlo al hacer refresh se pierde el contexto
  //TODO, moreInfo debe tener toda la data necesaria para guardarla en memoria, hacer query on refresh
  const [salaId, setSalaId] = useState(null)
  const [info, setInfo] = useState({})
  const [ads, setAds] = useState([])
  const [sorteosConf, setSorteosConf] = useState(null);
  const [loadingData, setLoadingData] = useState(true)

  const {loading, data} = useFetch(Apis.USER + '/users/me?type=casino', null)
  const {loading: loadingSorteos, data: sorteosConfData} = useFetch(Apis.SORTEOS + '/sorteo-conf?salaId=' + salaId)
  const history = useHistory()

  useEffect(() => {
    const salaId = localStorage.getItem('salaId')

    if(salaId === undefined || salaId === null){
      history.push("/selector")
      return
    }

    setSalaId(salaId)
    const sala = JSON.parse(localStorage.getItem('sala'))
    setInfo({sala: sala})

    getPub(salaId)
  }, [])

  useEffect(() => {
    setSorteosConf(sorteosConfData)

    if(data && data.user !== undefined){
      datadogRum.setUser({
        //id: '1234',
        name: data.user.name
      })
    }

  }, [data])

  const getPub = (salaId) => {
    axios.get(Apis.PC + '/ads-banners/active?salaId='+salaId).then(res => {
      setAds(res.data)
      setLoadingData(false)
    }).catch(err => {
      setLoadingData(false)
    })
  }

  return (
    <SiteContext.Provider value={{salaId, setSalaId, info, setInfo, ads, sorteosConf, setSorteosConf}}>
      {
        loading ?
          <div>Cargando...</div> :
          props.children
      }
    </SiteContext.Provider>
  );
}
