import React from "react";
import {Route} from "react-router-dom";
import {useWauth} from "./WauthProvider";

function PrivateRoute({component: Component, ...rest}) {

  const {isAuthenticated, loginWithRedirect} = useWauth()

  if (!isAuthenticated) {
    loginWithRedirect()
    return
  }

  return (
    <Route
      {...rest}
      render={props =>
        <Component {...props} />
      }
    />
  );
}

export default PrivateRoute;
