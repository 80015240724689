import React from "react"
import { useHistory } from "react-router"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import styled from "styled-components"
import * as MdIcons from 'react-icons/md'
import ButtonCustom from "../components/ButtonCustom"
import Text from "../styledComponents/TextStyled"

const ContenidoIdle = styled.div`
  height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
  gap:32px;
`

const IdleHasWinner = () => {
  const history = useHistory()

  return (
    <ContainerBackgroundWrapper>
      <ContenidoIdle>
        <Text textStyle='large'>¡El sorteo seleccionado ya tiene un ganador registrado!</Text>
        <ButtonCustom onClick={() => history.push('/sorteo-list')}>
          <MdIcons.MdHome fontSize={32} />
          <span>Inicio</span>
        </ButtonCustom>
      </ContenidoIdle>
    </ContainerBackgroundWrapper>
  )
}

export default IdleHasWinner;
