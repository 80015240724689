import styled, {keyframes}from "styled-components"
import { textStyle } from 'styled-system'

export const textScaleOnce = keyframes`
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`
const textScaleRepeat = keyframes`
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
`

export const fadeInBottom = keyframes`
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`

export const pulsateBck = keyframes`
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
`

export const TextGold = styled.span`
  ${textStyle}
  animation: ${textScaleOnce} 10s cubic-bezier(0.680, -0.550, 0.265, 1.550) alternate both infinite;
  color: #D5AD6D; 
  background: -webkit-linear-gradient(transparent, transparent),
  -webkit-linear-gradient(left, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform:uppercase;
  text-align:center;
`

export const TextGoldRepeat = styled.span`
  ${textStyle}
  animation: ${textScaleRepeat} 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate 1s both;
  color: #D5AD6D;
  background: -webkit-linear-gradient(transparent, transparent),
  -webkit-linear-gradient(top, rgba(213, 173, 109, 1) 0%, rgba(213, 173, 109, 1) 26%, rgba(226, 186, 120, 1) 35%, rgba(163, 126, 67, 1) 45%, rgba(145, 112, 59, 1) 61%, rgba(213, 173, 109, 1) 100%);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform:uppercase;
  text-align:center;
`

const Text = styled.span`
  text-transform: uppercase;
  color:white;
  text-align:center;
  ${textStyle}
`

Text.defaultProps = {
  textStyle: 'medium'
}

TextGold.defaultProps = {
  textStyle: 'medium'
}

TextGoldRepeat.defaultProps = {
  textStyle: 'medium'
}

export default Text