import React, { useContext, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Apis } from "../util/Apis"
import styled, { keyframes } from "styled-components"
import CustomLoading from "../components/CustomLoading"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import ButtomCustom, { ContentButtons } from "../components/ButtonCustom"
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs'
import * as FaIcons from 'react-icons/fa'
import LogoCenter from "../components/LogoCenter"
import Text, { TextGold } from "../styledComponents/TextStyled"
import { getCurrencyConfig } from "../components/services";
import { SiteContext } from "../context/SiteContext";
import GetHotSeatName from "../util/GetHotSeatName"
import axios from "axios"
import CountdownTimer from "../components/CountdownTimer"
import useGet from "../hooks/useGet"
import useToggle from "../hooks/useToggle"
import { usePost } from "../hooks/usePost"

const ScaleUp = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
`


const ContainerIdle = styled.div`
  height: calc(80vh - 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  transition: all 0.5s ease;
  padding-top:1rem;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(80vh - 1rem);
    padding-top:0;
  }
`
const ContentIdle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  justify-content: space-evenly;
`

const ContentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const ContenedorNumero = styled.div`
  font-size: 8rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:1rem;
  width: 70vw;
  .input{
    max-width: 40vw;
    border: none;
    box-shadow: 0px 0px 6px 2px inset rgb(0 0 0 / 60%), 0 10px 10px 0px rgb(0 0 0 / 60%);
    border-radius: 1rem;
    font-size:3rem;
    text-align:center;
    font-weight:900;
    padding:1rem;

    &:disabled{
      background:#d30000;
      color:white;
    }
    &:focus{
      animation: ${ScaleUp} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      outline: none;
      transform: scale(1.1);
      box-shadow: 0px 0px 6px 2px inset rgb(0 0 0 / 60%), 0 15px 10px 0px rgb(0 0 0 / 60%);
    }
  }
`

const SwitchSorteoButton = ({ id, kind, getCupon }) => {
  const history = useHistory();
  let component;

  switch (kind) {
    case 'VIRTUAL':
      component =
        <>
          <ButtomCustom onClick={() => history.push('/sorteo-animation/' + id)}>
            <BsIcons.BsPlay fontSize={32} />
            <span>Comenzar</span>
          </ButtomCustom>
          <ButtomCustom onClick={() => history.push('/sorteo/' + id + '/participantes')}>
            <FaIcons.FaUsers fontSize={32} />
            <span>Participantes</span>
          </ButtomCustom>
        </>
      break;
    case 'HOT_SEAT':
      component =
        <>
          <ButtomCustom onClick={() => history.push('/sorteo-animation-hotseat/' + id)}>
            <BsIcons.BsPlay fontSize={32} />
            <span>Comenzar</span>
          </ButtomCustom>
          <ButtomCustom onClick={() => history.push('/sorteo/' + id + '/machines')}>
            <MdIcons.MdListAlt fontSize={32} />
            <span>Máquinas Participantes</span>
          </ButtomCustom>
        </>
      break;
    case 'MANUAL':
      component =
        <>
          <ButtomCustom onClick={() => getCupon()}>
            <MdIcons.MdOutlineContentPasteSearch fontSize={30} />
            <span>Validar</span>
          </ButtomCustom>
        </>
      break;
    default:
      component = <></>
  }
  return (
    <ContentButtons>
      <ButtomCustom onClick={() => history.push('/sorteo-list/' + id)}>
        <BsIcons.BsHouse fontSize={30} />
        <span>Inicio</span>
      </ButtomCustom>
      {component}
    </ContentButtons>
  );
};

const IdlePage = () => {
  const currencyConfig = getCurrencyConfig()

  const {salaId,setSorteosConf} = useContext(SiteContext);
  const history = useHistory();
  const { id } = useParams();

  const { isOpen: hasWinner, setIsOpen: setHasWinner } = useToggle({})
  const [codCoupon, setCodCoupon] = useState('');
  const [message, setMessage] = useState(null);

  const {loading, data} = useGet({
    url: Apis.SORTEOS + '/sorteos/' + id,
    initialValue: []
  })

  const { data: couponType } = useGet({
    url: `${Apis.PC}/coupon-type?salaId=${salaId}`
  })

  const sorteosConf = useGet({
    url: `${Apis.SORTEOS}/sorteo-conf?salaId=${salaId}`,
    onSuccess: (data) => setSorteosConf(data)
  })

  const { mutate } = usePost({
    url: Apis.SORTEOS + '/sorteo-winner',
    onSuccess: () => {
      setTimeout(() => {
        history.push('/winner-view/' + id)
      }, 1000)
    }
  })

  const sorteoData = useMemo(() => couponType && couponType.filter(item => item.id === couponType.couponTypeId).map(item => item.name), [couponType])

  const getTimeForRuffle = (hora) => {
    let tiempoRecibido = new Date(hora).getTime()
    const now = new Date().getTime()
    if (tiempoRecibido > now) {
      return 1
    }
  }

  const getCupon = () => {
    axios.get(`${Apis.PC}/coupon/${codCoupon}/validate-dto?salaId=${salaId}`, {})
      .then(res => {
        setMessage('Ganador encontrado')
        setHasWinner(true)
        if (res.status === 200) saveWinner(res.data.confId, res.data.customerId, res.data.couponId)
      }).catch(err => {
        setHasWinner(false)
        if (err.response.status === 403) {
          setMessage('El cupón no es valido')
        } else {
          setMessage(err.response.data.message)
        }
      })
  }

  const saveWinner = async (confId, customerId, couponId) => {
    const params = {
      sorteoId: id,
      salaId: salaId,
      confId: confId,
      customerId: customerId,
      couponId: couponId,
      nroActa: sorteosConf.nroActa
    }

    await mutate(params);
  }

  return (
    <ContainerBackgroundWrapper>
      {
        !loading && data ?
          <>
            <LogoCenter mainview={false} />

            <ContainerIdle>
              {data.kind === 'HOT_SEAT' ?
                <TextGold textStyle='xlarge'>{GetHotSeatName()}</TextGold>
                :
                <TextGold textStyle='xlarge'>{sorteoData}</TextGold>
              }
              {data.kind === 'MANUAL' ?
                <ContentIdle>
                  <ContenedorNumero>
                    <Text>PREMIO</Text>
                    {data.premioTipo === 'soles' ?
                      <TextGold textStyle='xlarge'>{currencyConfig.symbol} {data.amount} {currencyConfig.currency}</TextGold>
                      :
                      <TextGold textStyle='xlarge'>{data.amount} {data.premioTipo}</TextGold>
                    }
                  </ContenedorNumero>
                  <ContenedorNumero>
                    <Text style={{ marginBottom: '16px' }} textStyle='large'>{message}</Text>
                    <input type="text" className='input' maxLength={15} value={codCoupon} onChange={e => setCodCoupon(e.target.value)} autoFocus />
                    <Text textStyle='small'>Ingrese el número del cupón</Text>
                  </ContenedorNumero>
                </ContentIdle>
                :
                <ContentIdle>
                  <ContentDetails>
                    <Text>PREMIO</Text>
                    {data.premioTipo === 'soles' ?
                      <TextGold textStyle='xlarge'>{currencyConfig.symbol} {data.amount} {currencyConfig.currency}</TextGold>
                      :
                      <TextGold textStyle='xlarge'>{data.amount} {data.premioTipo}</TextGold>
                    }
                  </ContentDetails>

                  {getTimeForRuffle(data.fechaHora) > 0 ?
                    <ContentDetails>
                      <Text textStyle='medium'>El sorteo empezará dentro de:</Text>
                      <Text textStyle='xlarge'>
                        <CountdownTimer targetDate={data.fechaHora} />
                      </Text>
                    </ContentDetails>
                    :
                    <Text textStyle='medium'>El sorteo empezará en breve</Text>
                  }
                </ContentIdle>
              }
              <SwitchSorteoButton id={id} kind={data.kind} getCupon={getCupon} saveWinner={saveWinner} hasWinner={hasWinner} />
            </ContainerIdle>
          </>
          :
          <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default IdlePage;
