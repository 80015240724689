const baseIp = 'https://api4.wargos.cloud'
const api5 = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({
  // BASE: 'http://localhost:9044',
  // CAPP: 'http://localhost:9078',

  BASE: baseIp,
  CAPP: `${baseIp}/xcustomerapp`,
  SORTEOS: `${baseIp}/xsorteos`,
  TGM: `${baseIp}/xtgmconex`,
  PC:`${baseIp}/xpointcore`,
  USER: `${api5}/xuser`,
});
