import React from "react";
import ContainerBackgroundWrapper from "../components/Backgrounds";
import { useParams } from "react-router"
import { Apis } from "../util/Apis"
import LogoCenter from "../components/LogoCenter"
import CustomLoading from "../components/CustomLoading"
import { ContainerBackground, ContentCard, CustomCard, CustomSwiper, HeaderCustom, ItemRow } from "./SorteoParticipants"
import Text, { TextGold } from "../styledComponents/TextStyled"
import * as MdIcons from 'react-icons/md'
import NoSorteos from "../components/NoSorteos"
import { getCurrencyConfig } from "../components/services";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { truncateName } from "../util";
import useToggle from "../hooks/useToggle";
import useGet from "../hooks/useGet";
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

const SorteoMachines = () => {
  const currencyConfig = getCurrencyConfig()
  const { id } = useParams()
  const { isOpen: hideName, setIsOpen: setHideName } = useToggle({});

  const { loading, data } = useGet({
    url: Apis.SORTEOS + '/sorteos/' + id + '/participants',
    initialValue: []
  })

  return (
    <ContainerBackgroundWrapper>
      {!loading ?
        <>
          <LogoCenter mainview={true} />
          {data.length === 0 ?
            <NoSorteos>
              <Text textStyle='small'>Este sorteo aún no tiene participantes</Text>
            </NoSorteos>
            :
            <ContainerBackground>

              <div style={{ display: 'flex', flexDirection: 'column', height: '50%', gap: '32px' }}>
                <CustomCard>
                  <ContentCard>
                    <Text textStyle='xlarge'> {data.length}</Text>
                    <MdIcons.MdGroups fontSize={64} />
                  </ContentCard>
                  <TextGold>PARTICIPANTES</TextGold>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
                    onClick={() => setHideName(!hideName)}
                  >
                    {hideName ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    <Text textStyle='small'>{hideName ? 'Mostrar nombres' : 'Ocultar nombres'}</Text>
                  </div>
                </CustomCard>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <HeaderCustom>
                  <Text textStyle='small' style={{ width: '150px', textAlign: 'center' }}>Nro. máquina</Text>
                  <Text textStyle='small' style={{ textAlign: 'left', flex: 1 }}>Jugador</Text>
                  <Text textStyle='small' style={{ width: '150px', textAlign: 'center' }}>Ap. Promedio</Text>
                </HeaderCustom>
                <CustomSwiper
                  slidesPerView={12}
                  loop={true}
                  spaceBetween={0}
                  direction={"vertical"}
                  speed={1000}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false
                  }}
                  modules={[Autoplay]}
                >
                  {data.map(item => (
                    <SwiperSlide key={item.sessionId}>
                      <ItemRow>
                        <Text style={{ width: '150px', textAlign: 'center' }}>{item.machineName}</Text>
                        <Text style={{ textAlign: 'left', flex: 1 }}>
                          {item.customerId === null ?
                            'CLIENTE SIN TARJETA'
                            :
                            truncateName(item.firstName, item.lastName1, hideName)
                          }
                        </Text>
                        <Text style={{ width: '150px', textAlign: 'center' }}>
                          {item.apProm === null ?
                            (currencyConfig.symbol + ' 0')
                            :
                            currencyConfig.symbol + ' ' + item.apProm.toFixed(2)
                          }
                        </Text>
                      </ItemRow>
                    </SwiperSlide>
                  ))}
                </CustomSwiper>
              </div>
            </ContainerBackground>
          }
        </>
        :
        <CustomLoading />
      }

    </ContainerBackgroundWrapper>
  )
}

export default SorteoMachines;
