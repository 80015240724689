import React from 'react'
import {useHistory} from "react-router"
import styled from "styled-components"

import { DateTime } from "luxon"
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs'
import Text from '../styledComponents/TextStyled'
import {getCurrencyConfig} from "./services";
import GetHotSeatName from '../util/GetHotSeatName'

const GridCustom = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  cursor: pointer !important;
  color: white;
  align-items: center;
  text-align:center;
`
const GridCustomItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap:8px;
`

export default function CardSorteo({item}) {
  const {
    anforaId,
    sorteoWinnerId: winnerId,
    id: sorteoId,
    premioTipo,
    kind,
    couponTypeName,
    amount, couponTypeId
  } = item

  const history = useHistory();

  const verificarVidaSorteo = (winnerId, sorteoId, kind, couponTypeId) => {
    localStorage.setItem('anforaId', anforaId)
    if (kind === 'TORNEO') {
      history.push('/torneo-view-rank/' + couponTypeId)
    }else{
      history.push('/idle/' + sorteoId)
    }
  }

  const currencyConfig = getCurrencyConfig()

  function getTypeRaffle() {
    switch(kind) {
      case 'MANUAL':
        return 'MANUAL'
      case 'VIRTUAL':
        return 'VIRTUAL'
      case 'HOT_SEAT':
        return 'HOT SEAT'
      case 'TORNEO':
        return 'TORNEO'
      default:
        return 'Manual'
    }
  }

  function getFormattedHour(datetime){
    return DateTime.fromISO(datetime).toFormat('hh:mm a')
  }
  
  return (
    <GridCustom onClick={() => verificarVidaSorteo(winnerId, sorteoId,kind,couponTypeId)}>
      <GridCustomItem>
        <MdIcons.MdOutlineAccessTime fontSize={32}/>
        <Text textStyle='large'> {getFormattedHour(item.fechaHora)} </Text>
      </GridCustomItem>
      {kind === 'HOT_SEAT' ?
        <GridCustomItem>
          <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
            <MdIcons.MdLocalFireDepartment fontSize={32}/>
            <Text textStyle='small'> {getTypeRaffle()} </Text>
          </div>
          <Text textStyle='large'>{GetHotSeatName()}</Text>
        </GridCustomItem> :
        <GridCustomItem>
        <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
          {kind==='TORNEO'?
          <BsIcons.BsTrophy fontSize={32}/>
          :
          <MdIcons.MdLocalPlay fontSize={32}/>
          }
          <Text textStyle='small'> {getTypeRaffle()} </Text>
        </div>
          
          <Text textStyle='large'> {couponTypeName} </Text>
        </GridCustomItem>
      }
      {premioTipo === 'soles' ?
        <GridCustomItem>
          <MdIcons.MdLocalAtm fontSize={32}/>
          <Text textStyle='large'>{currencyConfig.symbol} {amount}</Text>
        </GridCustomItem>
        :
        <GridCustomItem>
          <BsIcons.BsGiftFill fontSize={32} />
          <Text textStyle='large'>{amount} {premioTipo}</Text>
        </GridCustomItem>
      }
      <GridCustomItem>
        {winnerId ? <BsIcons.BsCheck2Square fontSize={32}/> : <MdIcons.MdHourglassTop fontSize={32}/>}
        <Text textStyle='large'>{winnerId ? "SORTEADO" : "POR SORTEAR"}</Text>
      </GridCustomItem>
    </GridCustom>
  );
}
