import React from 'react'
import { motion } from "framer-motion-3d"
import { useGLTF } from '@react-three/drei'

const AnforaAnimate = ({tiempo,error}) => {

  const { nodes, materials } = useGLTF('/anfora.glb')
  console.log( "tiempo recibido total de anfora: "+tiempo)
  const girosAnfora = Math.trunc(tiempo/2)

  return (
    <group dispose={null}>
      <motion.group
        rotation={[-Math.PI / 3, 0, Math.PI / 2]}
        animate={{ 
          rotateX: [-Math.PI / 3, ((girosAnfora)*6-1)*Math.PI / 3]
        }}
        transition={{ 
          ease: "easeInOut",
          duration: tiempo-6,
          delay:1
        }}
        >
        <motion.mesh
          animate={{ y: 65 }}
          transition={{ 
            delay:tiempo-5,
            ease: "easeInOut",
            duration: 2
          }}
         geometry={nodes.tapa.geometry} material={materials['Brass Glossy']} />
        <motion.mesh geometry={nodes.text.geometry} material={materials['Gold Glossy 27']} position={[-97.96, -86.27, 7.79]} scale={1.24} />
        <motion.mesh geometry={nodes.vertex.geometry} material={materials['Gold Glossy 7']} position={[0.01, 0, -91.65]} />
        <motion.mesh geometry={nodes.body.geometry} material={materials['Gold Glossy 2']} position={[0, -0.5, -89.87]} />
        <motion.group
          animate={{ 
            scale: error ? 1 : [1, 1, 2],
            x: error ? 0 : [0,0,-300],
            y: error ? 0 : [0,0,-200],
            z: error ? 0 : [0, 100, 150],
            rotateY:[0,0,Math.PI/3 ],
            rotateX:[0,0,Math.PI/2 ]
          }}
          transition={{
            delay: tiempo-3,
            duration: 3
          }}
        >
          <motion.mesh geometry={nodes.data.geometry} material={materials['Refresh 1']} position={[11.66, -0.22, 12.81]} rotation={[0, 0, Math.PI]} />
          <motion.mesh geometry={nodes.coupon.geometry} material={materials.Columbia} position={[12.09, -17.5, 28]} rotation={[-Math.PI / 2, 0, Math.PI]} />
        </motion.group>
      </motion.group>
    </group>
  )
}

export default AnforaAnimate