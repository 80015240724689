import React, {useEffect, useState} from 'react'
import ContainerBackgroundWrapper from '../components/Backgrounds'
import star from '../assets/images/star.svg'
import {
  BloqueLista,
  BloqueTitulo,
  ItemClasificado,
  ItemRank
} from '../styledComponents/TorneoStyled'
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import moment from 'moment'
import defaultLogo from '../assets/images/logo.png'
import { LogoImage } from '../components/LogoCenter'
import Text, { TextGold } from '../styledComponents/TextStyled'
import NoSorteos from '../components/NoSorteos'
import {getCurrencyConfig} from "../components/services";
import CountdownTimer from '../components/CountdownTimer'

const TorneoViewRankCore = ({sala, torneo}) => {

  const couponTypeId = torneo.couponTypeId
  const fechaDelTorneo = moment(torneo.fechaHora).format('YYYY-MM-DD HH:mm:ss')

  const countDownDate = new Date(moment(torneo.fechaHora).format('YYYY-MM-DD HH:mm:ss')).getTime();
  const now = new Date().getTime()
  const distance = countDownDate - now;

  const [estado, setEstado] = useState(false);

  let refreshTimer = null

  const {
    data,
    fetchData
  } = useFetch(Apis.PC + '/coupon/group-by-customer?salaId=' + sala.id + '&couponTypeId=' + couponTypeId, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEstado(true);
      //TODO, debemos detener el refresco automatico, cuando el sorteo acabo
      clearTimeout(refreshTimer)
    }, distance);
    return () => clearTimeout(timeout);
  })

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
    }, 2000);
    return () => clearInterval(interval);
  }, [fetchData])

  let logoImg = sala.logo
  if (logoImg === undefined) {
    logoImg = defaultLogo
  }

  const currencyConfig = getCurrencyConfig()

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      <BloqueTitulo style={{height:'calc(20vh - 1rem)'}}>
        <LogoImage src={logoImg} alt='logowhite' />
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'8px'}}>
          <TextGold >{torneo.couponTypeName}</TextGold>
          <Text>CLASIFICACIÓN</Text>

          {!estado &&
            <CountdownTimer targetDate={fechaDelTorneo} />
          }
        </div>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'8px'}}>
          <Text textStyle='small'>PREMIO</Text>
          <TextGold textStyle='small'>{torneo.amount} {currencyConfig.currency}</TextGold>
        </div>
      </BloqueTitulo>

      <BloqueLista style={{height:'calc(80vh - 1rem)'}}>
        <div style={{display:'grid', gridTemplateColumns:'80px 1fr 100px',marginTop:'0.5rem', fontSize:'0.9rem',width:'100%'}}>
          <div style={{paddingLeft:'20px'}}>Rank</div>
          <div style={{textAlign:'left'}}>Jugador</div>
          <div style={{paddingRight:'20px'}}>Puntos</div>
        </div>

        {!data.length > 0 ?
          <NoSorteos>
            <Text textStyle='small'>aun no hay jugadores participantes...</Text>
          </NoSorteos>
          :
          <div style={{overflowY:'auto',width:'100%'}} className='custom-scroll'>
            {data.slice(0, 100).map(item =>
              <ItemClasificado key={item.rank} cantidad={torneo.winnerQuantity + 1} style={{margin:'1rem 0.5rem',gridTemplateColumns: '80px 1fr 100px'}}>
                <ItemRank>
                  <img src={star} alt='star'/>
                  <span>{item.rank}</span>
                </ItemRank>
                <Text style={{textAlign:'left',fontSize:'20px'}}>{item.firstName + ' ' + item.lastName1.substring(0, 2) + item.lastName1.replace(/./g, '*')}</Text>
                <Text style={{fontSize:'20px'}}>{item.total}</Text>
              </ItemClasificado>
            )
            }
          </div>
        }
      </BloqueLista>
    </ContainerBackgroundWrapper>
  );
};

export default TorneoViewRankCore;
