import React from 'react';
import styled from "styled-components";
import moment from 'moment'
import Text, { TextGold, TextGoldRepeat } from '../styledComponents/TextStyled'
import * as MdIcons from 'react-icons/md'
import { IconMaquinas } from './Icons'
import 'moment/locale/es';

export const CardWinnerContainer = styled.div`
  width: 100%;
  height: calc(85vh - 2rem);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: center;
  transition: all 0.5s ease;
  color:white;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(85vh - 1rem);
  }
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  gap: 16px;
  align-items: center;
`


export default function CardWinner(props) {

  function mostrarCodCupon() {
    return props.salaId !== 70;
  }

  function getDocumentType() {
    if (props.salaId === 70) {
      return 'ID'
    } else {
      return 'DNI'
    }
  }

  function getWinnerName() {
    if (props.salaId === 70) {
      return 'CLIENTE SOLBET'
    } else {
      return props.winnerName
    }
  }


  function getCouponHour(datetime){
    return moment(datetime).format("hh:mm a")
  }

  function getCouponDate(datetime){
    if (moment().format("YYYY-MM-DD") > moment(datetime).format("YYYY-MM-DD")) {
      return moment(datetime).locale('es').format("DD [de] MMMM");
    }
  }

  return (
    <CardWinnerContainer>
      {props.winnerName &&
        <>
          <TextGold textStyle='xlarge'>FELICIDADES</TextGold>
          <ContainerText>
            <Text textStyle='xlarge'>{getWinnerName()}</Text>
            <Text textStyle='xlarge'>{getDocumentType() + ": " + props.winnerDocument}</Text>
          </ContainerText>
          <ContainerText>
            <Text>ES NUESTRO</Text>
            <TextGoldRepeat textStyle='xlarge'>¡GANADOR(A)!</TextGoldRepeat>
          </ContainerText>
          {mostrarCodCupon()
            &&
            <ContainerText>
              {props.couponCode && 
                <ContainerText flexDirection='row'>
                  <Text textStyle='xlarge'>CUPÓN: {props.couponCode}</Text>
                  <MdIcons.MdLocalPlay fontSize={54}/>
                </ContainerText>
              }
              
              <ContainerText flexDirection='row' style={{gap:'128px'}}>
                {props.machine && 
                  <ContainerText flexDirection='row'>
                    <Text textStyle='xlarge'>Máquina: {props.machine}</Text>
                    <IconMaquinas fontSize={'54px'} />
                  </ContainerText>
                }
                {props.couponCreatedAt && 
                  <ContainerText style={{gap:'0px'}}>
                    <ContainerText flexDirection='row'>
                      <Text textStyle='xlarge'>{getCouponHour(props.couponCreatedAt)}</Text>
                      <MdIcons.MdOutlineAccessTime fontSize={54}/>
                    </ContainerText>
                    <Text textStyle='large'>{getCouponDate(props.couponCreatedAt)}</Text>
                  </ContainerText>
                }
              </ContainerText>

            </ContainerText>
          }
        </>
      }
    </CardWinnerContainer>
  );
}
