import React, { useContext, useState } from 'react'
import Confetti from 'react-confetti'
import styled from "styled-components"
import useWindowSize from '../hooks/useWindowSize'
import ContainerBackgroundWrapper from "../components/Backgrounds"
import { Apis } from "../util/Apis";
import CustomLoading from '../components/CustomLoading'
import { useHistory, useParams } from "react-router"
import { ItemClasificado, ItemRank } from '../styledComponents/TorneoStyled'
import star from '../assets/images/star.svg'
import ButtonCustom, { ContentButtons } from '../components/ButtonCustom'
import Text, { TextGold, TextGoldRepeat } from '../styledComponents/TextStyled'
import { SiteContext } from "../context/SiteContext";
import useGet from '../hooks/useGet'

const ContainerTorneo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 4rem);
  justify-content: space-evenly;
  color: white;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(100vh - 2rem);
  }
`

const CANTIDAD_GANADORES = 1;

const TorneoView = () => {
  const history = useHistory();
  const size = useWindowSize();

  const { id } = useParams()
  const { salaId } = useContext(SiteContext)

  const [sizeIni, setSizeIni] = useState(0)
  const [sizeFin, setSizeFin] = useState(5)

  const { loading, data } = useGet({
    url: Apis.PC + '/coupon/group-by-customer?salaId=' + salaId + '&couponTypeId=' + id,
    initialValue: []
  })

  const next5 = () => {
    setSizeIni(sizeIni + 5);
    setSizeFin(sizeFin + 5)
  }

  const retornar = () => {
    setSizeIni(0);
    setSizeFin(5)
  }

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      <Confetti
        width={size.width}
        height={size.height}
        opacity={0.8}
      />

      {!loading ?
        <>
          <ContainerTorneo>
            <TextGold textStyle='xlarge'>FELICIDADES</TextGold>
            {data.filter(item => item.rank === 1).map(item =>
              <Text key={item.rank} textStyle='xlarge'>{item.firstName + ' ' + item.lastName1}</Text>
            )}
            <Text>ES NUESTRO(A)</Text>
            <TextGoldRepeat textStyle='xlarge'>GANADOR(A)!</TextGoldRepeat>

            <div style={{ width: '80%' }} className='custom-scroll'>
              {data.slice(sizeIni, sizeFin).map(item =>
                <ItemClasificado key={item.rank} cantidad={CANTIDAD_GANADORES + 1}>
                  <ItemRank>
                    <img src={star} alt='star' />
                    <Text>{item.rank}</Text>
                  </ItemRank>
                  <Text style={{ textAlign: 'left' }}>{item.firstName + ' ' + item.lastName1}</Text>
                  <Text>{item.total}</Text>
                </ItemClasificado>
              )
              }
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '80%', gap: '16px' }}>
              <span style={{ cursor: 'pointer' }} onClick={next5}>siguientes 5+</span>
              <span style={{ cursor: 'pointer' }} onClick={retornar}>inicio</span>
            </div>

            <ContentButtons>
              <ButtonCustom onClick={() => history.push('/sorteo-list')}>CONFIRMAR</ButtonCustom>
            </ContentButtons>
          </ContainerTorneo>
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  );
};

export default TorneoView;
