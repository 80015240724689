import { useState } from 'react';

const useToggle = ({
  onOpen = () => { },
  onClose = () => { },
  defaultValue = false
}) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const toggler = (props) => {
    const nextIsOpen = !isOpen;
    setIsOpen(nextIsOpen);
    if (nextIsOpen) {
      onOpen(props);
    } else {
      onClose(props);
    }
  };

  return { isOpen, toggler, setIsOpen };
};

export default useToggle;