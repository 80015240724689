export function getCurrencyConfig() {
  const defaultGlobalConfig = {
    currency: "SOLES",
    symbol: "S/"
  }

  const lsConfig = localStorage.getItem("globalConf")

  if (lsConfig === undefined || lsConfig === null) {
    return defaultGlobalConfig
  }

  let result = {}
  try {
    result = JSON.parse(lsConfig)
  } catch (e) {
    return defaultGlobalConfig
  }


  return {currency: result.currency, symbol: result.symbol}
}
