import React, { useContext, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core"
import styled from "styled-components"
import LogoCenter from "../components/LogoCenter"
import { Apis } from "../util/Apis"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import AnforaDetails from "../components/AnforaDetails"
import CustomLoading from "../components/CustomLoading"
import { SiteContext } from "../context/SiteContext";
import { getFirstLetters, labelOfPagina } from "../util";
import useGet from "../hooks/useGet";

const ContainerAnfora = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(65vh - 1rem);
`

const ContainerAnforaLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
`

const ContainerAnforaRight = styled.div`
  width: 60%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TableFooterCustom = styled(TableFooter)`
  background: ${(props) => props.theme.tableHead};
  border-radius: 5px 5px 0 0;
  box-shadow: rgb(0 0 0 / 50%) 0 0 10px 5px;
  color: white;
`

const TableRowCustom = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.tableItem};
  }

  &:nth-of-type(even) {
    background-color: rgb(255 255 255 / 4%);
  }
`
const ultimosParticipantes = [
  {
    id: 1,
    name: "José Carlos",
    lastName: "Medina",
    quantity: 530
  },
  {
    id: 2,
    name: "Andres",
    lastName: "L",
    quantity: 200
  },
  {
    id: 3,
    name: "Eder",
    lastName: "Q",
    quantity: 120
  }
]


const AnforaView = () => {

  const { salaId } = useContext(SiteContext)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const anforaId = localStorage.getItem('anforaId')

  const { loading, data } = useGet({
    url: Apis.PC + '/anforas/' + anforaId,
    initialValue: [],
  })

  const couponType = useGet({
    url: `${Apis.PC}/coupon-type?salaId=${salaId}`,
  })

  const emptyRows = useMemo(() => rowsPerPage - Math.min(rowsPerPage, ultimosParticipantes.length - page * rowsPerPage), [rowsPerPage, page]);

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ContainerBackgroundWrapper>
      {!loading ?
        <>
          <LogoCenter />
          <ContainerAnfora>
            <ContainerAnforaLeft>
              <AnforaDetails name={data?.name} quantity={!loading ? data.currentQuantity : ' '}
                couponId={data?.couponTypeId} couponTypeData={!couponType.loading && couponType.data}
              />
            </ContainerAnforaLeft>
            <ContainerAnforaRight>
              <p style={{ fontSize: "2rem", fontWeight: "bold", margin: "1rem" }}>Últimos Participantes</p>
              <div style={{
                width: "90%",
                maxHeight: "calc(100vh - 340px)",
                overflowY: "auto",
                boxShadow: "rgb(0 0 0 / 50%) 0px 0px 10px 5px"
              }} className='custom-scroll'>
                <TableContainer>
                  <Table>
                    <TableFooterCustom>
                      <TableRow>
                        <TablePagination style={{ color: "white" }}
                          rowsPerPageOptions={[5, 10]}
                          colSpan={4}
                          count={ultimosParticipantes.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          labelRowsPerPage={"Participantes por página"}
                          labelDisplayedRows={() => labelOfPagina(page * (rowsPerPage) + 1, ((page + 1) * (rowsPerPage) > ultimosParticipantes.length) ? ultimosParticipantes.length : (page + 1) * (rowsPerPage), ultimosParticipantes.length)}
                        />
                      </TableRow>
                    </TableFooterCustom>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? ultimosParticipantes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : ultimosParticipantes).map((participante, index) => (
                          <TableRowCustom key={participante.name}>
                            <TableCell align={"center"}
                              style={{ fontSize: "1.5rem", color: "white" }}>{participante.id}</TableCell>
                            <TableCell align={"center"} style={{
                              fontSize: "1.5rem",
                              color: "white"
                            }}>{getFirstLetters(participante.name, participante.lastName)}</TableCell>
                            <TableCell align={"center"}
                              style={{ fontSize: "1.5rem", color: "white" }}>{participante.quantity}</TableCell>
                          </TableRowCustom>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 66 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </ContainerAnforaRight>
          </ContainerAnfora>
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default AnforaView;
