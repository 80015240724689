import React, { useContext, useEffect, useRef, useMemo, useState } from "react"
import styled from "styled-components"
import { useHistory, useParams } from "react-router"
import axios from "axios";
import { Apis } from "../util/Apis";
import gif from "../assets/gifs/hot-seat-grande-infinito.gif"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import tiempoAnimacion from '../assets/dataSala/tiempoAnimacion.json'
import Text from "../styledComponents/TextStyled"
import { SiteContext } from "../context/SiteContext";

const BackgroundPlay = styled.div`
  height: calc(100vh - 2rem);
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    width: 93%;
  }
  @media (max-width: 425px) {
    width: 96%;
  }
`

const ShadowMachine = styled.div`
  width: 65vw;
  height: 30vh;
  background: radial-gradient(rgba(0, 0, 0, 0.8) 10%, transparent 80%);
  position: absolute;
  top: 60vh;
  border-radius: 50%;
  z-index: -1;
`

const PlayHotSeat = () => {
  const { id } = useParams();
  const history = useHistory();

  const { salaId } = useContext(SiteContext);

  const [tiempoDefault, setTiempoDefault] = useState(8000)
  const timerRef = useRef(null);

  const result = useMemo(() => tiempoAnimacion.find(item => item.salaId === salaId), [salaId]);

  useEffect(() => {
    let auxTiempoDefault = tiempoDefault
    if (result) {
      auxTiempoDefault = result.winnerAnimationTime
      setTiempoDefault(auxTiempoDefault)
    }
    generateWinner(auxTiempoDefault)

    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const generateWinner = (tiempoDefault) => {
    const startTime = new Date()

    axios.post(Apis.SORTEOS + '/sorteos/' + id + '/generate-winner-hot-seat').then(() => {
      const endTime = new Date();
      const timeDiff = endTime - startTime;

      if (timeDiff > tiempoDefault) showWinner()
      else timerRef.current = setTimeout(() => {
        showWinner()
      }, tiempoDefault - timeDiff);
    })
  }

  const showWinner = () => history.push('/winner-view-hotseat/' + id)

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      <BackgroundPlay>
        <Text textStyle='xlarge'>Buscando al ganador</Text>
        <ShadowMachine />
        <img src={gif + "?a=" + Math.random()} alt={"anfora"} style={{ paddingTop: "2rem", width: "60%" }} />
      </BackgroundPlay>
    </ContainerBackgroundWrapper>
  )
};

export default PlayHotSeat;
