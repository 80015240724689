import React, {useEffect} from "react";
import {useWauth} from "../auth/WauthProvider";

const LoginPage = () => {

  const {loginWithRedirect} = useWauth();

  useEffect(() => {
    loginWithRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>Cargando...</div>
  )
};

export default LoginPage;
