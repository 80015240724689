import styled from "styled-components"
import slotmachine from "../assets/images/slot-machine.svg"

export const IconMaquinas = styled.div`
  width: ${props => props.fontSize || "24px"};
  height: ${props => props.fontSize || "24px"};
  background-color: #fff;
  -webkit-mask: url(${slotmachine}) no-repeat center;
  mask: url(${slotmachine}) no-repeat center;
`
