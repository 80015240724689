import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {datadogRum} from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'b21091bd-2f6e-4e99-9616-9e06407aaa72',
  clientToken: 'pubd35402b2e071a6010ae093e13942b3cc',
  site: 'datadoghq.com',
  service: 'fe-sorteos',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingOrigins: ["https://sorteos.wgt.pe", "https://api4.wgt.pe", "https://sorteos.wargos.cloud", "https://dreams.sorteos.wargos.cloud", "https://api4.wargos.cloud", "https://api5.wargos.cloud"]
});

datadogRum.startSessionReplayRecording();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
