import React, { useMemo } from 'react'
import Confetti from 'react-confetti'
import ContainerBackgroundWrapper from '../components/Backgrounds'
import CardWinnerProgressive from '../components/CardWinnerProgressive';
import useWindowSize from '../hooks/useWindowSize';
import { useParams, useHistory } from 'react-router';
import { Apis } from '../util/Apis';
import ButtonCustom, { ContentButtons } from '../components/ButtonCustom';
import CustomLoading from '../components/CustomLoading';
import useGet from '../hooks/useGet';

const ProgressiveWinnerView = () => {
  const { id } = useParams();
  const size = useWindowSize();
  const history = useHistory();

  const { loading, data } = useGet({
    url: Apis.TGM + '/progressive-level?progressiveId=' + id,
    initialValue: []
  })

  const pozoWinner = useMemo(() => data && data.reduce((a, b) => (a.winnerDatetime > b.winnerDatetime ? a : b), 0), [data])

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      {!loading ?
        <>
          <Confetti
            width={size.width}
            height={size.height}
            opacity={0.8}
          />
          <CardWinnerProgressive
            name={'Pozo: ' + pozoWinner.name}
            machine={pozoWinner.winnerMachineName}

          />

          <ContentButtons>
            <ButtonCustom onClick={() => history.push('/sorteo-list')}>CONFIRMAR</ButtonCustom>
          </ContentButtons>
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default ProgressiveWinnerView