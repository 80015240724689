import React, { useState, useEffect, useRef } from 'react'
import ContainerBackgroundWrapper from "../components/Backgrounds"
import star from '../assets/images/star.svg'
import { CircularProgress } from '@material-ui/core'
import { BloqueLista, BloqueTitulo, ItemClasificado, ItemRank, TituloTorneo, ListaFinal, LoadingFinal, ContenedorTimer } from '../styledComponents/TorneoStyled'
import ButtonCustom from '../components/ButtonCustom'
import { LogoImage } from '../components/LogoCenter'
import Text from '../styledComponents/TextStyled'
import { getDeadLineTime, getTimeRemaining } from '../util'

const Finalistas = [
  {
    position: 1,
    name: "PEDRO RUIZ",
    points: "0"
  },
  {
    position: 2,
    name: "TOMAS ALMENARA",
    points: "0"
  },
  {
    position: 3,
    name: "GILBERTO RAMOS",
    points: "0"
  },
  {
    position: 4,
    name: "MARICIELO ZAPATA",
    points: "0"
  },
  {
    position: 5,
    name: "GUILLERMO TERRONES",
    points: "0"
  },
  {
    position: 6,
    name: "SANDRO DE LA CRUZ",
    points: "0"
  },
  {
    position: 7,
    name: "MIGUEL ANGEL FERNANDEZ",
    points: "0"
  },
  {
    position: 8,
    name: "BRENDA PAREDES",
    points: "0"
  }
]

const TorneoView = () => {
  const [logoUrl, setLogoUrl] = useState('logo')
  const [comenzarSorteo, setComenzarSorteo] = useState(false);
  const [showList, setShowList] = useState(true)
  const [timer, setTimer] = useState('01 : 00');

  const intervalRef = useRef(null)

  useEffect(() => {
    const auxLogo = localStorage.getItem("logo")
    setLogoUrl(auxLogo)

    setTimeout(function () {
      setShowList(false);
    }, 5000);
  }, []);

  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline)
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) + ' : ' +
        (seconds > 9 ? seconds : '0' + seconds)
      )
    } else {
      clearInterval(intervalRef.current)
    }
  }

  function clearTimer(endtime) {
    setTimer('01 : 00')
    if (intervalRef.current) clearInterval(intervalRef.current)
    const id = setInterval(() => {
      startTimer(endtime)
    }, 1000)
    intervalRef.current = id
  }

  function onClickReset() {
    clearTimer(getDeadLineTime())
    setComenzarSorteo(true)
  }

  return (
    <ContainerBackgroundWrapper>
      <BloqueTitulo>
        <LogoImage src={logoUrl} alt='logo' />
        <TituloTorneo>TORNEO RULETA</TituloTorneo>
        <Text textStyle='large'>FINAL</Text>
      </BloqueTitulo>
      <BloqueLista>
        {showList ?
          <LoadingFinal>
            <CircularProgress style={{ color: "white" }} />
            <Text textStyle='small'>Cargando lista de participantes finalistas...</Text>
          </LoadingFinal>
          :
          <ListaFinal>

            <ContenedorTimer>
              {timer !== '00 : 00' ?
                <>
                  <span style={{ fontSize: '1.8rem', fontWeight: '900' }}>{timer}</span>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem', fontWeight: 'lighter' }}>
                    <span style={{ marginRight: '1rem' }}>min</span>
                    <span style={{ marginLeft: '1rem' }}>seg</span>
                  </div>
                  {comenzarSorteo ?
                    <Text textStyle='small'>Tiempo restante</Text>
                    :
                    <ButtonCustom onClick={onClickReset} style={{ marginTop: '0.5rem' }}>INICIAR TORNEO </ButtonCustom>
                  }
                </>
                :
                <Text textStyle='small'>El torneo a concluido</Text>
              }
            </ContenedorTimer>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr', marginTop: '2rem', fontSize: '0.9rem', width: '100%' }}>
              <div style={{ paddingLeft: '24px' }}>Rank</div>
              <div style={{ textAlign: 'left' }}>Jugador</div>
              <div style={{ paddingRight: '24px' }}>Puntos</div>
            </div>

            <div style={{ overflowY: 'auto', width: '100%' }}>
              {Finalistas.map(item =>
                <ItemClasificado key={item.position}>
                  <ItemRank>
                    <img src={star} alt='star' />
                    <span>{item.position}</span>
                  </ItemRank>
                  <Text style={{ textAlign: 'left' }}>{item.name}</Text>
                  <Text>{item.points}</Text>
                </ItemClasificado>
              )
              }
            </div>

          </ListaFinal>
        }
      </BloqueLista>
    </ContainerBackgroundWrapper>
  );
};

export default TorneoView;
