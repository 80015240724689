import React, { useContext } from "react";
import ContainerBackgroundWrapper from "../components/Backgrounds"
import styled from "styled-components"
import { useHistory } from "react-router"

import { Apis } from "../util/Apis"
import CustomLoading from "../components/CustomLoading"
import Text from "../styledComponents/TextStyled"
import globalConfSala from '../assets/dataSala/tiempoAnimacion.json'
import { SiteContext } from "../context/SiteContext";
import { getLogoURL } from "../util/logoUtils";
import { logoutUser } from "../login/LogoutPage";
import useGet from "../hooks/useGet";

const TitleSelector = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  height: calc(15vh - 1rem);
  text-align: center;
  transition: all 0.5s ease;
  @media (max-width: 768px) {
    transition: all 0.5s ease;
    font-size: 24px;
  }
  @media (max-width: 425px) {
    transition: all 0.5s ease;
    font-size: 16px;
  }
`
const SalaList = styled.div`
  height: calc(85vh - 3rem);
  overflow-y: auto;
  transition: all 0.5s ease;
  @media (max-width: 768px) {
    transition: all 0.5s ease;
    height: calc(85vh - 2rem);
  }
`
const GridList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem;
  grid-gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.7rem;
    padding: 0.5rem;
    max-height: 85%;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
    grid-gap: 0.4rem;
    max-height: 87%;
  }

`
const SalaButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.item};
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 40%);
  transition: all .3s ease;
  border-radius: 5px;
  color: white;
  padding: 1rem;

  &:hover {
    background: ${(props) => props.theme.itemSelect};
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 40%);
    transform: scale(1.05);
  }
`
const SalaLogo = styled.img`
  height: 70px;
  transition: all .3s ease;
  margin: 0.8rem 0;
  @media (max-width: 768px) {
    height: 65px;
  }
  @media (max-width: 425px) {
    height: 60px;
  }
`

const SalaSelector = () => {

  const history = useHistory()

  const { loading, data } = useGet({
    url: Apis.USER + '/users/me?type=casino',
    initialValue: null
  })

  const {setSalaId, setInfo} = useContext(SiteContext)

  const selectSala = (sala) => {
    setSalaId(sala.id)
    setInfo({ sala: sala })

    localStorage.setItem('salaId', sala.id)
    localStorage.setItem('sala', JSON.stringify(sala))
    localStorage.setItem('globalConf', JSON.stringify(globalConfSala.filter(item => item.salaId === sala.id)[0]))

    history.push('sorteo-list')
  }

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      {!loading ?
        <>
          <TitleSelector>
            SELECCIONE UNA SALA
          </TitleSelector>
          <div className="button-exit" style={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}>
            <SalaButton variant={'danger'} onClick={logoutUser}>Cerrar Sesión</SalaButton>
          </div>
          <SalaList className="custom-scroll">
            <GridList>
              {
                data && data.salas.map((sala, index) =>
                  <SalaButton key={sala.id} onClick={() => selectSala(sala)}>
                    <SalaLogo alt={'logo'} src={getLogoURL(sala.logo)} />
                    <Text textStyle='small'>{sala.name}</Text>
                  </SalaButton>
                )
              }
            </GridList>
          </SalaList>
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default SalaSelector;
