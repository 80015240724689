import styled, {keyframes} from "styled-components"

export const textScaleTitulo = keyframes`
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  10% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

export const fadeInBottom = keyframes`
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`

export const pulsateBck = keyframes`
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
`

export const BloqueTitulo = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: calc(25vh - 2rem);
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(25vh - 1rem);
  }
`

export const BloqueLista = styled.div`
  width: 100%;
  color: white;
  text-align: center;
  align-items: center;
  height: calc(75vh - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(75vh - 1rem);
  }
`

export const TituloTorneo = styled.span`
  animation: ${textScaleTitulo} 10s cubic-bezier(0.680, -0.550, 0.265, 1.550) alternate both infinite;
  margin: 0rem;
  font-size: 64px;
  color: #D5AD6D; 
  background: -webkit-linear-gradient(transparent, transparent),
  -webkit-linear-gradient(left, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform:uppercase;
  font-weight: 900;
`

export const ItemClasificado = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: 1rem;
  padding: 0.5rem 0;
  border-radius: 5px;
  align-items: center;
  background: ${(props) => props.theme.itemRankBackground};
  box-shadow: 0px 0px 5px 2px ${(props) => props.theme.itemRankShadow};
  text-align:center;
  &:nth-child(n+ ${props => props.cantidad}) {  
    box-shadow: 0px 0px 5px 2px #0000008f;
    img{
        display:none;
      }
    span{
      color: white;
      font-weight:400;
    }
  }
`

export const ItemRank = styled.div`
  position: relative;
  img{
    position: absolute;
    animation: ${pulsateBck} 3s ease-in-out infinite both;
    top: -27px;
    left: 0;
    width: 100%;
    height: 60px;
  }
  span{
    color: rgb(131,0,0);
    position: absolute;
    width: 100%;
    left: 0;
    top: -13px;
    font-weight: bold;
    font-size: 1.5rem;
  }
`

export const LoadingFinal = styled.div`
  animation: ${fadeInBottom} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  display: flex;
  flex-direction:column;
  align-items: center;
  padding-top: 2rem;
  span{
    color:white;
    padding-top:2rem;
  }
`

export const ListaFinal = styled.div`
  animation: ${fadeInBottom} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`

export const ClasificacionConcluida = styled.div`
  animation: ${fadeInBottom} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  display: flex;
  padding-top: 2rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  gap:16px;
`

export const ContenedorTimer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`