import styled from "styled-components"

const NoSorteos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.43);
  box-shadow: rgb(0 0 0 / 50%) 0 0 5px 5px;
  border-radius: 16px;
  transition: all 0.5s ease 0s;
  margin: 2rem 4rem;
  padding: 2rem;
  gap: 16px;

`

export default NoSorteos