import React, {useContext} from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import ButtonCustom from "./ButtonCustom";
import {SiteContext} from "../context/SiteContext";
import {getLogoURL} from "../util/logoUtils";
import { BsChevronLeft, BsHouse } from "react-icons/bs";

const CabecerawithNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  height: calc(20vh - 3rem);
  padding-top: 1rem;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    padding-top: 0rem;
    height: calc(20vh - 1rem);
  }
`
export const LogoImage = styled.img`
  height: calc(20vh - 4rem);
  cursor: pointer;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    padding-top: 0rem;
    height: calc(20vh - 3rem);
  }
`

const LogoCenter = ({mainview = true}) => {

  const history = useHistory()
  const {info} = useContext(SiteContext)
  const logoUrl = info.sala.logo

  return (
    <CabecerawithNav>
      {mainview &&
        <ButtonCustom onClick={() => history.goBack()}>
          <BsChevronLeft />
          <span>VOLVER</span>
        </ButtonCustom>
      }

      <LogoImage src={getLogoURL(logoUrl)} onClick={() => history.push("/selector")}/>

      {mainview &&
        <ButtonCustom onClick={() => history.push("/sorteo-list")}>
          <BsHouse />
          <span>INICIO</span>
        </ButtonCustom>
      }
    </CabecerawithNav>
  )
}

export default LogoCenter
