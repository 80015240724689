import React from 'react'
import {useParams} from "react-router"
import TorneoViewRankCore from "./TorneoViewRankCore";

const TorneoViewRankPublic = () => {

  const {hash} = useParams()

  let sala = null
  let torneo = null

  if (hash === 'XB5TcAUYP0F6SotfXxUEdgrBmLoeWd6R') {
    sala = {
      id: 61,
      name: 'Fiesta',
      logo: 'https://res.cloudinary.com/dghqjv5az/image/upload/v1624040636/mix/dreamsv2/Dreams-Fiesta-B_juirwu.png'
    }
    torneo = {
      couponTypeId: 365,
      couponTypeName: "TORNEO RULETA",
      amount: "3000",
      premioTipo: "soles",
      fechaHora: "2022-10-01 18:00:00"
    }

  } else {
    return <div> Lo sentimos, pagina no encontrada</div>
  }

  return (
    <TorneoViewRankCore sala={sala} torneo={torneo}></TorneoViewRankCore>
  )
}

export default TorneoViewRankPublic
