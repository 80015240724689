import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from "react-router"
import ContainerBackgroundWrapper from '../components/Backgrounds'
import styled from "styled-components"
import fullborder from '../assets/images/bg-progressive.png'
import { Apis } from "../util/Apis"
import Snowfall from 'react-snowfall'
import Odometer from 'react-odometerjs'
import useGet from '../hooks/useGet'
import { getSizeAmount, getSizeTitle } from '../util'
import 'odometer/themes/odometer-theme-default.css';

const ContainerPozo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
`

const Pozobg = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height:60%;
  width: 100%;
  position:relative;
`

const TextName = styled.span`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:4vw;
  font-weight: 900;
  color: #750000;
  text-shadow: 0 2px 4px #2d0000;
`

const TextAmount = styled(Odometer)`
  position: absolute !important;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: ${props => props.fontSize || '9vw'};
  font-weight: 900;
  color: #750000;
  text-shadow: 0 2px 4px #2d0000;
  transition: all 0.5s ease;
  @media (max-width: 2560px) {
    transition: all 0.5s ease;
    text-shadow: 0 6px 10px #2d0000;
  }
  @media (max-width: 1024px) {
    transition: all 0.5s ease;
    text-shadow: 0 4px 8px #2d0000;
    top: 57%;
  }
`

const ProgressiveView = () => {
  const { id } = useParams()
  const history = useHistory();

  const [images, setImages] = useState([])

  const { data: dataProgressive, fetchData } = useGet({
    url: Apis.TGM + '/progressive/' + id,
  })

  const states = useMemo(() => dataProgressive && dataProgressive?.levels.map(item => item.winner), [dataProgressive])

  useEffect(() => {
    const snowflake2 = document.createElement('img')
    snowflake2.src = '/assets/itemfall.png'

    setImages([snowflake2, snowflake2])

    const interval = setInterval(() => fetchData(), 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!states) return

    const goToView = (id) => history.push('/progressive-winner-view/' + id)

    const interval = setInterval(() => {
      if (Object.values(states).some(el => el === true)) goToView(dataProgressive.id)
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [states])



  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      <Snowfall radius={[5, 15]} snowflakeCount={180} images={images} />
      <ContainerPozo>
        {dataProgressive?.levels.map((item, index) => (
          <Pozobg img={fullborder} key={index}>
            <TextName style={getSizeTitle(dataProgressive?.levels.length)}>{item.name}</TextName>
            <TextAmount
              style={getSizeAmount(dataProgressive?.levels.length)}
              format='(,ddd).dd'
              duration={1000}
              value={item.currentAmount}
            />
          </Pozobg>
        ))}
      </ContainerPozo>

    </ContainerBackgroundWrapper>
  )
}

export default ProgressiveView
