import React from 'react';
import styled from "styled-components"

const TextValue = styled.span`
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
`
const TextType = styled.span`
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
`

const DateTimeDisplay = ({ value, type }) => {
  return (
    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <TextValue>{value}</TextValue>
      <TextType>{type}</TextType>
    </div>
  );
};

export default DateTimeDisplay;