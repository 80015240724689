import React, { useMemo } from "react";
import ContainerBackgroundWrapper from "../components/Backgrounds"
import styled from "styled-components"
import LogoCenter from "../components/LogoCenter"
import { Apis } from "../util/Apis"
import CustomLoading from "../components/CustomLoading"
import Text, { TextGold } from "../styledComponents/TextStyled"
import * as MdIcons from 'react-icons/md'
import NoSorteos from "../components/NoSorteos";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import useGet from "../hooks/useGet";
import useToggle from "../hooks/useToggle";
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import { truncateName } from "../util";

export const ContainerBackground = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 32px;
  height: calc(80vh - 6rem);
  color: white;
  padding: 32px;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(80vh - 5rem);
  }
`
export const CustomSwiper = styled(Swiper)`
  height:calc(80vh - 6rem - 43px) !important;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height:calc(80vh - 4rem - 43px) !important;
  }

  .swiper-wrapper {
    -webkit-transition-timing-function:linear!important; 
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important; 
  }
  .swiper-slide {
    &:nth-of-type(odd) {
    background-color: transparent;
    }
    &:nth-of-type(even) {
      background-color: ${(props) => props.theme.tableItem};
    }
  }
`

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap:16px;
  padding: 8px 16px;
  width: 100%;
`
export const HeaderCustom = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  gap:16px;
  padding:8px 16px;
  background: ${(props) => props.theme.tableHead};
  border-radius: 8px 8px 0 0;
  box-shadow: rgb(0 0 0 / 50%) 0 0 10px 5px;
`

export const CustomCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.itemBackground};
  box-shadow: ${(props) => props.theme.shadowBorderColor} 0px 0px 5px 5px;
  background: ${(props) => props.theme.itemBackground} !important;
`
export const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease 0s;
  color: white;
`

export const CustomCheckbox = styled.input`
  display: none;

  &::before {
    content: '\2713';
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    color: #ef2e2e;
    border-radius: 3px;
  }

  &:checked::before {
    background-color: red;
  }
`

const SorteoParticipants = () => {
  const { id } = useParams()
  const { isOpen: hideName, setIsOpen: setHideName } = useToggle({});

  const { loading, data } = useGet({
    url: Apis.SORTEOS + `/sorteos/${id}/participantes/v2`,
    initialValue: [],
  })

  const totalCupones = useMemo(() => {
    const total = data.reduce((a, b) => a + b.quantity, 0)
    const numberFormatter = Intl.NumberFormat('en-US');
    return numberFormatter.format(total);
  }, [data])

  return (
    <ContainerBackgroundWrapper>
      {!loading ?
        <>
          <LogoCenter mainview={true} />
          {data.length === 0 ?
            <NoSorteos>
              <Text textStyle='small'>Este sorteo aún no tiene participantes</Text>
            </NoSorteos>
            :
            <ContainerBackground>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '32px' }}>
                <CustomCard>
                  <ContentCard>
                    <Text textStyle='xlarge'> {data.length}</Text>
                    <MdIcons.MdGroups fontSize={64} />
                  </ContentCard>
                  <TextGold>PARTICIPANTES</TextGold>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
                    onClick={() => setHideName(!hideName)}
                  >
                    {hideName ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    <Text textStyle='small'>{hideName ? 'Mostrar nombres' : 'Ocultar nombres'}</Text>
                  </div>
                </CustomCard>
                <CustomCard>
                  <ContentCard>
                    <Text textStyle='xlarge'> {totalCupones} </Text>
                    <MdIcons.MdLocalPlay fontSize={64} />
                  </ContentCard>
                  <TextGold>CUPONES DEPOSITADOS</TextGold>
                </CustomCard>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <HeaderCustom>
                  <Text textStyle='small' style={{ textAlign: 'left', flex: 1 }}>Jugador</Text>
                  <Text textStyle='small' style={{ width: '150px', textAlign: 'right' }}>Cupones</Text>
                </HeaderCustom>
                <CustomSwiper
                  slidesPerView={12}
                  loop={true}
                  spaceBetween={0}
                  direction={"vertical"}
                  speed={1000}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false
                  }}
                  modules={[Autoplay]}
                >
                  {data.map(item => (
                    <SwiperSlide key={item.customerId}>
                      <ItemRow>
                        <Text style={{ textAlign: 'left', flex: 1 }}>
                          {truncateName(item.firstName, item.lastName1, hideName)}
                        </Text>
                        <Text style={{ width: '150px', textAlign: 'right' }}>{item.quantity}</Text>
                      </ItemRow>
                    </SwiperSlide>
                  ))}
                </CustomSwiper>
              </div>
            </ContainerBackground>
          }
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default SorteoParticipants;
