import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import './App.css';
import IdlePage from "./pages/IdlePage";
import PlaySorteo from "./pages/PlaySorteo";
import CongratWinner from "./pages/CongratWinner";
import SorteoList from "./pages/SorteoList";
import IdleHasWinner from "./pages/IdleHasWinner";
import SalaSelector from "./pages/SalaSelector";
import LoginPage from "./login/LoginPage";
import axios from "axios";
import CallbackPage from "./login/CallbackPage";
import WauthProvider from "./auth/WauthProvider";
import LogoutPage from "./login/LogoutPage";
import HomePage from "./pages/HomePage";
import PrivateRoute from "./auth/PrivateRoute";
import AnforaView from "./pages/AnforaView";
import SorteoParticipants from "./pages/SorteoParticipants";
import PageWrapperStyled from './components/PageWrapper'
import PlayHotSeat from './pages/PlayHotSeat'
import CongratHotSeatWinner from './pages/CongratHotSeatWinner'
import SorteoMachines from './pages/SorteoMachines'
import TorneoViewRank from './pages/TorneoViewRank'
import TorneoViewFinal from './pages/TorneoViewFinal'
import TorneoViewRankPublic from "./pages/TorneoViewRankPublic"
import TorneoWinner from './pages/TorneoWinner';
import { ThemeProvider } from 'styled-components'
import { dreamsColor, generalColor, GlobalStyles } from './theme'
import { SiteProvider } from "./context/SiteContext";
import ProgressiveView from './pages/ProgressiveView';
import ProgressiveWinnerView from './pages/ProgressiveWinnerView';

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth');
  const salaId = localStorage.getItem('salaId');
  const tenant = localStorage.getItem('tenant');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (salaId && salaId > 0) {
    config.headers['X-Sala-ID'] = salaId
  }
  if (tenant) {
    config.headers['X-Tenant'] = tenant
  }

  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (401 === error.response.status) {
      window.location.href = '/logout';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

const SecureApp = () => {
  return (
    <SiteProvider>
      <PrivateRoute path={"/selector"} component={SalaSelector} />
      <PrivateRoute path={"/sorteo-list"} component={SorteoList} />
      <PrivateRoute exact path="/idle/:id" component={IdlePage} />
      <PrivateRoute path={"/sorteo/:id/participantes"} component={SorteoParticipants} />
      <PrivateRoute path={"/sorteo/:id/machines"} component={SorteoMachines} />
      <PrivateRoute path="/idle-has-winner/:id" component={IdleHasWinner} />
      <PrivateRoute exact path="/sorteo-animation/:id" component={PlaySorteo} />
      <PrivateRoute exact path="/sorteo-animation-hotseat/:id" component={PlayHotSeat} />
      <PrivateRoute exact path="/winner-view/:id" component={CongratWinner} />
      <PrivateRoute exact path="/winner-view-hotseat/:id" component={CongratHotSeatWinner} />
      <PrivateRoute path="/anfora" component={AnforaView} />
      <PrivateRoute exact path="/torneo-view-rank/:id" component={TorneoViewRank} />
      <PrivateRoute exact path="/torneo-view-final" component={TorneoViewFinal} />
      <PrivateRoute exact path="/torneo-winner/:id" component={TorneoWinner} />
      <PrivateRoute exact path="/progressive-view/:id" component={ProgressiveView} />
      <PrivateRoute exact path="/progressive-winner-view/:id" component={ProgressiveWinnerView} />
    </SiteProvider>
  )
}

function App() {

  let customTheme = generalColor
  if (window.location.host === 'dreams.sorteos.wargos.cloud') {
    customTheme = dreamsColor
  }

  return (
    <ThemeProvider theme={customTheme}>
      <WauthProvider
        domain={process.env.REACT_APP_AUTH_SERVER}
        clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
        redirectUri={window.location.origin + '/callback'}
      >
        <GlobalStyles />
        <PageWrapperStyled>
          <Switch>
            <Route path={"/home"} component={HomePage} />
            <Route exact path="/torneo-view-rank-p/:hash" component={TorneoViewRankPublic} />

            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/callback" component={CallbackPage} />
            <Route exact path="/logout" component={LogoutPage} />

            <Route exact path="/">
              <Redirect to="/home" />
            </Route>

            <Route path='/' component={SecureApp} />
          </Switch>
        </PageWrapperStyled>
      </WauthProvider>
    </ThemeProvider>
  );
}

export default App;
