import React from "react"
import styled from "styled-components"
import CircularProgress from '@material-ui/core/CircularProgress'
import Text from "../styledComponents/TextStyled"

const WrapLoading = styled.div`
  width: calc(100% - 4rem);
  height: calc(100vh - 4rem);
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    width: calc(100% - 2rem);
    height: calc(100vh - 2rem);
  }
`

const CustomLoading = () => {
  return (
    <WrapLoading>
      <CircularProgress style={{color: "white"}}/>
      <Text textStyle='small'>Cargando...</Text>
    </WrapLoading>
  )
}

export default CustomLoading
