import React, {useContext} from 'react'
import styled from "styled-components"
import {Swiper, SwiperSlide} from 'swiper/react'
import {Autoplay, Pagination} from 'swiper'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import Text from '../styledComponents/TextStyled'
import {SiteContext} from "../context/SiteContext";

const NoPub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  text-align: center;
  justify-content: center;
  gap: 16px;

  img {
    width: 300px;
  }
`

const Publicidad = () => {

  const {ads} = useContext(SiteContext)

  const getImgUrl = (imgUrl) => {
    const baseURL = 'https://res.cloudinary.com/dghqjv5az/image/upload/linkedwgt/'
    return baseURL + imgUrl
  }

  return (
    <>
      {ads && ads.length > 0 ?
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false
          }}
          modules={[Autoplay, Pagination]}
        >
          {ads.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img src={getImgUrl(item.url)} alt="img"/>
              </SwiperSlide>
            )
          })}
        </Swiper>
        :
        <NoPub>
          <Text textStyle='small' style={{color: 'black'}}>
            Publicidad no dispoible
          </Text>
        </NoPub>
      }
    </>
  )
}

export default Publicidad
