import styled from "styled-components"
import { buttonStyle } from 'styled-system'

export const ContentButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`

const ButtonCustom = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  outline: 0;
  border: 0;
  border-radius: 8px;
  padding: 12px 32px;
  text-transform: uppercase;
  line-height: normal;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  text-align:center;
  font-size:24px;
  cursor: pointer;
  min-width:180px;
  box-shadow: 0px 3px 4px #220100;
  ${buttonStyle}
 `

ButtonCustom.defaultProps = {
  variant: 'primary'
}

export default ButtonCustom
