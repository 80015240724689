import React from 'react'
import Text, { TextGold, TextGoldRepeat } from '../styledComponents/TextStyled'
import { CardWinnerContainer,ContainerText } from './CardWinner'
import { IconMaquinas } from './Icons'

const CardWinnerProgressive = (props) => {
  return (
    <CardWinnerContainer>
      {props.machine &&
      <>
        <TextGold textStyle='xlarge'>{props.name}</TextGold>

        <ContainerText flexDirection='row'>
          <Text textStyle='xlarge'>Máquina: {props.machine}</Text>
          <IconMaquinas fontSize={'64px'} />
        </ContainerText>
        <ContainerText>
          <Text>ES LA POSICIÓN</Text>
          <TextGoldRepeat textStyle='xlarge'>¡GANADORA!</TextGoldRepeat>
        </ContainerText>
        {props.winnerDocument &&
          <ContainerText>
            <Text textStyle='xlarge'>{'CLIENTE: ' + props.winnerName}</Text>
            <Text textStyle='xlarge'>{'DNI: ' + props.winnerDocument}</Text>
          </ContainerText>
        }
      </>
      }
    </CardWinnerContainer>
  )
}

export default CardWinnerProgressive