import React, { useContext, useState } from "react"
import styled from "styled-components"
import CardSorteo from "../components/CardSorteo"
import { Apis } from "../util/Apis"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import { AppBar, Tab, Tabs } from "@material-ui/core"
import LogoCenter from "../components/LogoCenter"
import CustomLoading from "../components/CustomLoading"
import CardTorneo from "../components/CardTorneo"
import NoSorteos from "../components/NoSorteos"
import Text from "../styledComponents/TextStyled"
import { SiteContext } from "../context/SiteContext";
import CardProgressive from "../components/CardProgressive";
import useGet from "../hooks/useGet"

const ContenedorSorteo = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.itemBackground};
  box-shadow: ${(props) => props.theme.shadowBorderColor} 0px 0px 5px 5px;
  background: ${(props) => props.theme.itemBackground} !important;
`
const BackgroundListRaffle = styled.div`
  background-color: transparent !important;
  padding: 1rem;
`

const TabsContainer = styled.div`
  width: 95vw;
  border-radius: 16px 16px 0px 0px !important;
`
const TabCustom = styled(Tab)`
  font-size: 1.4rem !important;
`
const AppBarCustom = styled(AppBar)`
  background-color: ${(props) => props.theme.backgroundAppBarCustom} !important;
  border-radius: 16px 16px 0px 0px !important;

  .Mui-selected {
    background: ${(props) => props.theme.itemBackground} !important;
    color: #ffffff !important;
    border-radius: 16px 16px 0px 0px !important;
    box-shadow: inset 0px -5px #FF9900;
  }
`
const RaffleList = styled.div`
  max-height: calc(80vh - 51px);
  overflow-y: auto;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    max-height: calc(80vh - 51px - 1rem);
  }
`
const SorteoList = () => {

  const { salaId } = useContext(SiteContext)
  const [value, setValue] = useState(0)
  const [torneoInfo, setTorneoInfo] = useState(null)

  const { loading, data } = useGet({
    url: Apis.SORTEOS + '/sorteos?salaId=' + salaId,
    initialValue: []
  })

  const _ = useGet({
    url: `${Apis.SORTEOS}/torneos/active/v2?salaId=${salaId}`,
    onSuccess: (data) => {
      if (data) {
        setTorneoInfo(data)
        localStorage.setItem('torneoData', JSON.stringify(data))
      }
    }
  })

  const { data: dataProgressive } = useGet({
    url: `${Apis.TGM}/progressive?salaId=${salaId}`,
  })

  const handleChange = (_, newValue) => setValue(newValue);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <ContainerBackgroundWrapper>
      {!loading
        ?
        <>
          <LogoCenter mainview={false} />
          <div style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "80vh"
          }}>
            <TabsContainer>
              <AppBarCustom position="static">
                <Tabs TabIndicatorProps={{ style: { background: "rgba(220, 0, 0, 0.5)" } }}
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <TabCustom label="SORTEOS" />
                  <TabCustom label="TORNEOS" />
                  <TabCustom label="MISTERIOSOS" />
                </Tabs>
              </AppBarCustom>
              <RaffleList className="custom-scroll">
                <TabPanel value={value} index={0}>
                  {data.length > 0 ?
                    <BackgroundListRaffle>
                      {data.map(item =>
                        <ContenedorSorteo key={item.id}>
                          <CardSorteo item={item} />
                        </ContenedorSorteo>
                      )}
                    </BackgroundListRaffle>
                    :
                    <NoSorteos>
                      <Text textStyle='small'>No hay sorteos pendientes</Text>
                    </NoSorteos>
                  }
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {torneoInfo && torneoInfo.length > 0 ?
                    <BackgroundListRaffle>
                      {torneoInfo.map(item =>
                        <ContenedorSorteo key={item.id}>
                          <CardTorneo item={item} />
                        </ContenedorSorteo>
                      )
                      }
                    </BackgroundListRaffle>
                    :
                    <NoSorteos>
                      <Text textStyle='small'>No hay torneos disponibles</Text>
                    </NoSorteos>
                  }
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {dataProgressive && dataProgressive.length > 0 ?
                    <BackgroundListRaffle>
                      {dataProgressive.map(item =>
                        <ContenedorSorteo key={item.id}>
                          <CardProgressive item={item} />
                        </ContenedorSorteo>
                      )
                      }
                    </BackgroundListRaffle>
                    :
                    <NoSorteos>
                      <Text textStyle='small'>No hay progresivos disponibles</Text>
                    </NoSorteos>
                  }
                </TabPanel>
              </RaffleList>
            </TabsContainer>
          </div>
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default SorteoList;
