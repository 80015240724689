import React,{useEffect,useState} from "react"
import styled from 'styled-components'

const WrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  transition: ease all .5s;
  scroll-behavior: smooth;
`

const PageWrapperStyled = ({children}) => {

  const [showBackgroundCard, setShowBackgroundCard] = useState(true)

  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '/home') {
      setShowBackgroundCard(false)
    }
  }, [showBackgroundCard])

  return (
    <WrapperStyled>
      {children}
    </WrapperStyled>
  )
}

export default PageWrapperStyled