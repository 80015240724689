import React, { useContext, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Apis } from "../util/Apis"
import CardWinner from "../components/CardWinner"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import CustomLoading from "../components/CustomLoading"
import Confetti from 'react-confetti'
import useWindowSize from '../hooks/useWindowSize'
import ButtomCustom, { ContentButtons } from "../components/ButtonCustom";
import * as MdIcons from 'react-icons/md'
import ButtonCustom from "../components/ButtonCustom"
import NoSorteos from "../components/NoSorteos"
import Text from "../styledComponents/TextStyled"
import axios from "axios";
import { SiteContext } from "../context/SiteContext";
import useGet from "../hooks/useGet"

const CongratWinner = () => {
  const history = useHistory();
  const { id } = useParams();
  const { salaId } = useContext(SiteContext);

  const size = useWindowSize();

  const [existWinner, setExistWinner] = useState(true)

  const { loading, data } = useGet({
    url: Apis.SORTEOS + '/sorteos/' + id + '/winner',
    initialValue: [],
    onSuccess: (data) => {
      if (data && !data.document) setExistWinner(false)
    }
  })

  const confirmWinner = () => {
    axios.post(Apis.SORTEOS + '/sorteo-winner/' + data.sorteoWinnerId + '/confirm').finally(() => {
      history.push('/sorteo-list')
    })
  }

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      {!loading ?
        <>
          {existWinner ?
            <>
              <Confetti
                width={size.width}
                height={size.height}
                opacity={0.8}
              />
              <CardWinner winnerName={data.firstName + ' ' + data.lastName1}
                winnerDocument={data.document}
                couponCode={data.couponId}
                salaId={salaId}
                machine={data.machine}
                couponCreatedAt={data.couponCreatedAt}
              />

              <ContentButtons style={{ justifyContent: 'space-evenly' }}>
                {data.kind === 'MANUAL' ?
                  <ButtonCustom onClick={() => history.goBack()} variant='secondary'>REINICIAR</ButtonCustom>
                  :
                  <ButtonCustom onClick={() => history.push('/sorteo-animation/' + id)} variant='secondary'>REINICIAR</ButtonCustom>
                }
                <ButtonCustom onClick={confirmWinner} >CONFIRMAR</ButtonCustom>
              </ContentButtons>
            </>
            :
            <NoSorteos>
              <Text textStyle='small'>No se encontró cupones participantes</Text>
              <ContentButtons>
                <ButtomCustom onClick={() => history.push('/sorteo-animation/' + id)}>
                  <MdIcons.MdPlayArrow fontSize={32} />
                  <span>Reintentar</span>
                </ButtomCustom>
                <ButtomCustom onClick={() => history.push('/sorteo-list')}>
                  <MdIcons.MdHome fontSize={32} />
                  <span>Inicio</span>
                </ButtomCustom>
              </ContentButtons>
            </NoSorteos>
          }
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default CongratWinner;
