import React from "react"
import styled from "styled-components"
import * as MdIcons from 'react-icons/md'
import Text, { TextGold } from "../styledComponents/TextStyled"

const GridCustom = styled.div`
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px;
  gap: 16px;
  cursor: pointer;
`

const AnforaDetails = ({name, quantity, couponId, couponTypeData}) => {

  return (
    <GridCustom>
      <TextGold textStyle='large'>{name}</TextGold>
      <MdIcons.MdHowToVote fontSize={64}/>
      <Text>N° CUPONES</Text>
      <Text> {quantity} {couponTypeData ? couponTypeData.map(cup => cup.id === couponId && cup.name) : ''}
      </Text>
    </GridCustom>
  )
}

export default AnforaDetails;