import React, { Suspense, useContext, useEffect, useRef, useState, useMemo } from "react"
import styled from "styled-components"
import { useHistory, useParams } from "react-router"
import axios from "axios"
import { Apis } from "../util/Apis"
import anforaDreams from "../assets/gifs/anfora-dreams-infinite.gif"
import anforaSolbet from "../assets/gifs/anfora-solbet-infinite.gif"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import ButtomCustom from "../components/ButtonCustom"
import tiempoAnimacion from '../assets/dataSala/tiempoAnimacion.json'
import { TextGoldRepeat } from "../styledComponents/TextStyled"
import { Canvas } from '@react-three/fiber'
import { Stage } from '@react-three/drei'
import AnforaAnimate from "../components/AnforaAnimate"
import { AnimatePresence, motion } from "framer-motion"
import useCountdown from "@bradgarropy/use-countdown"
import Confetti from "react-confetti";
import { SiteContext } from "../context/SiteContext";

const CountDownContainer = styled(motion.div)`
  color: white;
  display: ${props => props.complete ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 6rem;
`;

const BackgroundPlay = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  transition: all 0.5s ease;

  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(100vh - 2rem);
  }

  @media (max-width: 768px) {
    width: 93%;
  }
  @media (max-width: 425px) {
    width: 96%;
  }
`
const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.error ? "83%" : "100%"};
  transition: all 0.5s ease;
`

const PlaySorteo = () => {
  const history = useHistory()

  const { id } = useParams()
  const { salaId } = useContext(SiteContext)

  const [error, setError] = useState(null)
  const [count, setCount] = useState(0);
  const [complete, setComplete] = useState(false);
  const [tiempoConteo, setTiempoConteo] = useState(0);
  const [tiempoDefault, setTiempoDefault] = useState(14000);

  const timer = useRef();

  const result = useMemo(() => tiempoAnimacion.find(item => item.salaId === salaId), [salaId]);

  useEffect(() => {
    let auxTiempoDefault = tiempoDefault;

    if (result) {
      auxTiempoDefault = result.winnerAnimationTime;
      setTiempoConteo(result.countdownTime)
      setTiempoDefault(auxTiempoDefault)
      setComplete(!result.hasCountdown)
    }
    generateWinner(auxTiempoDefault)

    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const countdown = useCountdown({
    seconds: tiempoConteo / 1000,
    onCompleted: () => setComplete(true)
  })

  const generateWinner = (tiempoDefault) => {
    setError(null)
    setCount(count + 1)
    axios.post(Apis.SORTEOS + '/sorteos/' + id + '/generate-winner').then(() => {
      timer.current = setTimeout(() => {
        history.push('/winner-view/' + id)
      }, tiempoDefault)
    }).catch(err => setError(err))
  }

  const getAnforaAnimation = (salaId) => {
    if (salaId >= 60 && salaId < 70) {
      return (
        <img src={anforaDreams + "?a=" + Math.random()} alt={"anfora"} style={{ paddingTop: "2rem", width: '45vw' }} />
      )
    } else if (salaId === 70) {
      return <img src={anforaSolbet + "?a=" + Math.random()} alt={"anfora"}
        style={{ paddingTop: "2rem", width: '45vw' }} />
    } else {
      return (
        <Canvas camera={{ fov: 50 }}>
          <Suspense fallback={null}>
            <Stage intensity={1} environment='studio'>
              <AnforaAnimate tiempo={(tiempoDefault - tiempoConteo) / (1000)} error={error} />
            </Stage>
          </Suspense>
        </Canvas>
      )
    }
  }

  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>

      <CountDownContainer complete={+complete}>
        {complete && <Confetti />}
        <AnimatePresence>
          <motion.h1
            key={countdown.seconds}
            animate={{
              scale: [0, 3, 0],
              opacity: [0, 1, 0],
              position: 'absolute'
            }}
            initial={{
              opacity: 0
            }}
            transition={{
              ease: "easeOut",
              duration: 2
            }}
          >
            {countdown.seconds}
          </motion.h1>
        </AnimatePresence>
      </CountDownContainer>
      {complete &&
        <BackgroundPlay>
          <ContainerImg error={error}>
            <TextGoldRepeat textStyle='xlarge'>Buscando al ganador</TextGoldRepeat>
            <div key={count} style={{ height: 'inherit' }}>
              {getAnforaAnimation(salaId)}
            </div>
          </ContainerImg>
          {error &&
            <div style={{ display: 'flex', flexDirection: 'column', height: '17%', alignItems: 'center' }}>
              <h4>¡Ups! tuvimos un problema al generar el ganador</h4>
              <ButtomCustom onClick={() => generateWinner()}>Reintentar</ButtomCustom>
            </div>
          }
        </BackgroundPlay>
      }
    </ContainerBackgroundWrapper>
  )
};

export default PlaySorteo;
