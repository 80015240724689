import React from "react";
import { useWauth } from "../auth/WauthProvider";
import { BackgroundHome } from "../components/Backgrounds";
import styled from "styled-components";
import defaultHomeBg from "../assets/backgrounds/roulette-wheel.jpg";
import dreamsHomeBg from "../assets/backgrounds/Fondo-AppDreams-1.jpg";
import ButtonCustom from "../components/ButtonCustom";

const WelcomeBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  margin: 0px;
  padding: 1rem;
  background: ${(props) => props.theme.homeBackground};
  box-shadow: 0px 0px 10px 5px ${(props) => props.theme.shadowBorderColor};
  border-radius: 10px;
  color: white;
  @media screen and (max-width: 768px) {
    width: 60%;
  }
  @media screen and (max-width: 425px) {
    width: 80%;
  }
`
const HomeTitle = styled.span`
  font-size: 2rem;
  font-weight: bold;
`
const HomeSubtitle = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  padding: 2rem 0px;
  text-align: center;
`

const HomePage = () => {

  const { loginWithRedirect } = useWauth()

  let homeBackground = defaultHomeBg

  if (window.location.host === 'dreams.sorteos.wargos.cloud') homeBackground = dreamsHomeBg

  return (
    <BackgroundHome img={homeBackground}>
      <WelcomeBackground>
        <HomeTitle>SORTEOS</HomeTitle>
        <HomeSubtitle>Bienvenido, aquí podrá inicializar los sorteos</HomeSubtitle>
        <div style={{ marginBottom: "1rem" }}>
          <ButtonCustom onClick={() => loginWithRedirect()}>INGRESAR</ButtonCustom>
        </div>
      </WelcomeBackground>
    </BackgroundHome>
  )
}

export default HomePage;
