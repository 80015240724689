import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useParams } from "react-router"
import ContainerBackgroundWrapper from '../components/Backgrounds'
import star from '../assets/images/star.svg'
import {
  BloqueLista,
  BloqueTitulo,
  ClasificacionConcluida,
  ItemClasificado,
  ItemRank
} from '../styledComponents/TorneoStyled'
import { Apis } from "../util/Apis";
import moment from 'moment'
import ButtonCustom, { ContentButtons } from '../components/ButtonCustom'
import Text, { TextGold } from '../styledComponents/TextStyled'
import { LogoImage } from '../components/LogoCenter'
import NoSorteos from '../components/NoSorteos'
import { getCurrencyConfig } from "../components/services";
import { SiteContext } from "../context/SiteContext";
import { getLogoURL } from "../util/logoUtils";
import CountdownTimer from '../components/CountdownTimer'
import { useState } from 'react'
import { FaEyeSlash } from "react-icons/fa";
import { CustomCheckbox } from "./SorteoParticipants";
import { getCurrentTime } from '../util'
import useGet from '../hooks/useGet'

const TorneoView = () => {
  const { id } = useParams()
  const { salaId, info } = useContext(SiteContext)
  const history = useHistory();

  const params = { salaId: salaId, couponTypeId: id }
  const currencyConfig = getCurrencyConfig()
  const logoImg = info.sala.logo

  const [torneoData, setTorneoData] = useState([])
  const [data, setData] = useState([]);
  const [hideName, setHideName] = useState(true)

  const torneoSeleccionado = useMemo(() => torneoData.length > 0 && torneoData.find(item => item.couponTypeId === parseInt(id)), [torneoData, id])
  const fechaDelTorneo = useMemo(() => torneoSeleccionado && moment(torneoSeleccionado.fechaHora).format('YYYY-MM-DD HH:mm:ss'), [torneoSeleccionado])
  const countDownDate = useMemo(() => torneoSeleccionado && new Date(moment(torneoSeleccionado.fechaHora).format('YYYY-MM-DD HH:mm:ss')).getTime(), [torneoSeleccionado]);
  const distance = useMemo(() => countDownDate && countDownDate - getCurrentTime(), [countDownDate]);

  const { fetchData, loading } = useGet({
    url: Apis.PC + '/coupon/group-by-customer',
    params,
    auto: false,
    onSuccess: (data) => setData(data)
  })

  useEffect(() => {
    const torneoData = JSON.parse(localStorage.getItem("torneoData"))
    setTorneoData(torneoData || [])

    const interval = setInterval(() => {
      if (!loading) {
        fetchData(params);
      }
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [])

  return (
    <ContainerBackgroundWrapper>
      {torneoData.length > 0 && torneoSeleccionado &&
        <>
          <BloqueTitulo>
            <LogoImage src={getLogoURL(logoImg)} alt='logowhite' onClick={() => history.push("/sorteo-list")} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
              <TextGold textStyle='xlarge'>{torneoSeleccionado.couponTypeName}</TextGold>
              <Text>CLASIFICACIÓN</Text>

              {distance > 0 &&
                <CountdownTimer targetDate={fechaDelTorneo} />
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
              <Text textStyle='large'>PREMIO</Text>
              <TextGold textStyle='large'>{torneoSeleccionado.amount} {currencyConfig.currency}</TextGold>
            </div>
          </BloqueTitulo>

          <BloqueLista>
            {distance > 0 ?
              <>
                {!data.length > 0 ?
                  <NoSorteos>
                    <Text textStyle='small'>aun no hay jugadores participantes...</Text>
                  </NoSorteos>
                  :
                  <>
                    <div style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 1fr',
                      marginTop: '2rem',
                      fontSize: '0.9rem',
                      width: '90%'
                    }}>
                      <div style={{ paddingLeft: '24px' }}>Rank</div>
                      <div style={{ textAlign: 'left' }}>Jugador
                        <label style={{ paddingLeft: '5px' }}>
                          <FaEyeSlash />
                          <CustomCheckbox
                            type="checkbox"
                            className="ti-switch"
                            checked={hideName}
                            onChange={() => setHideName(!hideName)}
                          />
                        </label>
                      </div>
                      <div style={{ paddingRight: '24px' }}>Puntos</div>
                    </div>

                    <div style={{ overflowY: 'auto', width: '90%' }} className='custom-scroll'>
                      {data.slice(0, 100).map(item =>
                        <ItemClasificado key={item.rank} cantidad={torneoSeleccionado.winnerQuantity + 1}>
                          <ItemRank>
                            <img src={star} alt='star' />
                            <span>{item.rank}</span>
                          </ItemRank>
                          {
                            hideName ?
                              <Text
                                style={{ textAlign: 'left' }}>{item.firstName + ' ' + item.lastName1.substring(0, 2) + item.lastName1.replace(/./g, '*')}</Text>
                              :
                              <Text style={{ textAlign: 'left' }}>{item.firstName + ' ' + item.lastName1}</Text>
                          }
                          <Text>{item.total}</Text>
                        </ItemClasificado>
                      )
                      }
                    </div>
                  </>
                }
              </>
              :
              <ClasificacionConcluida>
                <Text>La clasificación a concluido</Text>
                <Text textStyle='small'>El ganador se mostrará en breve</Text>
                <ContentButtons style={{ justifyContent: 'space-evenly' }}>
                  <ButtonCustom onClick={() => history.push('/sorteo-list')} variant='secondary'>INICIO</ButtonCustom>
                  <ButtonCustom onClick={() => history.push('/torneo-winner/' + id)}>MOSTRAR GANADOR</ButtonCustom>
                </ContentButtons>
              </ClasificacionConcluida>
            }
          </BloqueLista>
        </>
      }


    </ContainerBackgroundWrapper>
  );
};

export default TorneoView;
